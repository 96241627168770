<template>
  <div class="container">
    <div
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      class="d-flex inner-item"
      style="cursor: pointer; position: relative"
    >
      <slot></slot>
      <div
        style="disable: block; min-width: 30px; height: 100%;"
        :class="{
          'inner-edit-icon ': isInnerEditIcon
        }"
      >
        <v-icon
          :class="`ml-${mlIcon}`"
          @click="$emit('onEdit')"
          v-show="isVisibleEdit"
          :size="sizeIcon"
          :disabled="disableState"
          >mdi-pencil-outline</v-icon
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sizeIcon: {
      default: 20,
      type: Number
    },
    mlIcon: {
      default: 2,
      type: Number
    },
    disableState: Boolean,
    innerItem: {
      default: false,
      type: Boolean
    },
    isInnerEditIcon: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      isVisibleEdit: false
    }
  },
  methods: {
    onMouseEnter() {
      this.isVisibleEdit = true
    },
    onMouseLeave() {
      this.isVisibleEdit = false
    }
  }
}
</script>
<style scoped>
.container {
  position: relative;
}
.inner-edit-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
